@import "../abstracts/variables";
@import "../abstracts/functions";
@import "../abstracts/keyframes";

.section {
}

.wrapper {
  &__ofset-wrap {
    @media screen and(max-width: 1024px) {
      overflow: auto;

      .blog {
        width: 942px;
      }

      .blog2 {
        width: 706px;
      }
    }
  }
  &__slider-pricing-detail {
    margin: 0 -15px;
    .items {
      padding: 0 15px;
    }
  }
  &__slider-pricing-plan {
    margin: 0 -15px;
    .items {
      padding: 0 15px;
    }

    .slick-dots {
      bottom: -3rem;
      li {
        button {
          background: rgba(255, 255, 255, 0.16);
          border-radius: 50%;
          width: 16px;
          height: 16px;
          &::before {
            content: "";
          }
        }

        &.slick-active {
          button {
            background-color: white;
          }
        }
      }
    }
  }
  &__profile-blog {
    padding: 1.5rem;
    border: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    .profile {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      object-fit: cover;
      @media screen and(max-width: $br-md) {
        width: 80px;
        height: 80px;
      }
    }

    .sosmed {
      display: flex;
      align-items: center;
      margin: 0 -5px;

      a {
        display: inline;
        margin: 0 5px;
      }
    }
  }
  &__pagination {
    ul {
      margin: 0 -5px;
      li {
        padding: 0 5px;
        a {
          width: 32px;
          height: 32px;
          border: 1px solid #f1f1f1;
          border-radius: 50% !important;
          color: color("black");
          display: flex;
          align-items: center;
          justify-content: center;
          outline-style: none;
          box-shadow: none;

          &.active {
            outline-style: none;
            box-shadow: none;
            background-color: color("green");
            color: white;
            border: none;
          }
          &:focus {
            outline-style: none;
            box-shadow: none;
          }
          &:active {
            outline-style: none;
            box-shadow: none;
          }
          &:hover {
            outline-style: none;
            box-shadow: none;
            background-color: color("green");
            color: white;
            border: none;
          }
        }
      }
    }
  }
  &__card-blog {
    .cover {
      width: 100%;
      height: 270px;
      object-fit: cover;
    }
    .head {
      display: flex;
      align-items: center;

      .tag {
        padding: 2px 15px;
        border-radius: 30px;
        border: 1px solid transparentize(color("black"), 0.5);
        color: color("black");
      }
    }
  }
  &__head-tag-blog {
    .head {
      display: flex;
      align-items: center;

      .tag {
        padding: 2px 15px;
        border-radius: 30px;
        border: 1px solid transparentize(color("black"), 0.5);
        color: color("black");
      }
    }
  }

  &__data-blog-head {
    .head {
      display: flex;
      align-items: center;

      .tag {
        padding: 2px 15px;
        border-radius: 30px;
        border: 1px solid transparentize(color("black"), 0.5);
        color: color("black");
      }
    }
  }
  &__slider-team {
    margin: 0 -15px;
    .items {
      padding: 0 15px;
    }

    .slick-dots {
      bottom: -3rem;
      li {
        button {
          background: rgba(255, 255, 255, 0.16);
          border-radius: 50%;
          width: 16px;
          height: 16px;
          &::before {
            content: "";
          }
        }

        &.slick-active {
          button {
            background-color: white;
          }
        }
      }
    }
  }
  &__card-team {
    padding: 2rem 1rem;
    background-color: white;

    .profile {
      width: 136px;
      height: 136px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0 auto;
    }

    .sosmed {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
  &__brand-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    img {
      margin-bottom: 15px;
      @media screen and(max-width: 1024px) {
        width: 210px;
      }
      @media screen and(max-width: $br-md) {
        width: 150px;
      }
      @media screen and(max-width: $br-sm) {
        width: 116px;
      }
      @media screen and(max-width: $br-xx) {
        width: 80px;
        margin-bottom: 8px;
      }
    }
  }
  &__modal-wrap {
    display: block;
    padding-right: 17px;
    visibility: hidden;
    transition: 0.5s;
    background-color: transparent !important;

    .bg__wrap {
      background-color: #dbdff199;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
    }

    .modal-dialog {
      z-index: 1059;

      .modal-content {
        padding: 40px 30px;

        .close__wrap {
          width: 32px;
          height: 32px;
          background: white;
          box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -10px;
          top: -10px;
        }
      }
    }
    &.show {
      visibility: visible;
    }
  }
  &__plan-price {
    padding: 1.5rem;
    background-color: color("green");

    &.trasparant {
      background-color: transparent;
      border: 1px solid #1c1c1e1a;
    }
    select {
      border-radius: 30px;
      background-color: #f5f5f5;
      height: 52px;
      border: 1px solid #f2f2f7;
      outline-style: none;
      box-shadow: none;

      &:hover {
        border-radius: 30px;
        background-color: #f5f5f5;
        height: 52px;
        border: 1px solid #f2f2f7;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        border-radius: 30px;
        background-color: #f5f5f5;
        height: 52px;
        border: 1px solid #f2f2f7;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        border-radius: 30px;
        background-color: #f5f5f5;
        height: 52px;
        border: 1px solid #f2f2f7;
        outline-style: none;
        box-shadow: none;
      }
    }
  }
  &__table-compare-group {
    table {
      table-layout: fixed;
      .w-f-first {
        width: 30%;
      }
      &.noborder {
        thead {
          tr {
            th {
              padding: 1.5rem 1rem;
              border: none;
            }
          }
        }
      }

      thead {
        tr {
          th,
          td {
            padding: 1.5rem 1rem;
            border: none;
          }
        }
      }

      tbody {
        tr {
          th,
          td {
            padding: 0 1rem;
            vertical-align: middle;
            border: none;
            height: 75px;
          }
          &:nth-of-type(odd) {
            th,
            td {
              background: #ffffff0f;
            }
          }
        }
      }
    }
  }
  &__accordion-wrap {
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.5rem;

      .arrow {
        flex-shrink: 0;
        width: 24px;
        height: 24px;

        span {
          display: inline-block;
          position: absolute;
          height: 1px;
          width: 100%;
          background-color: white;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.5s;
          &:nth-child(2) {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
    }

    .body {
      padding-top: 0;
      max-height: 0;
      visibility: hidden;
      opacity: 0;
      transition: 0.5s;
    }

    &.active {
      .head {
        .arrow {
          span {
            &:nth-child(2) {
              transform: translate(-50%, -50%) rotate(0);
            }
          }
        }
      }
      .body {
        padding-top: 0.5rem;
        max-height: 50vh;
        visibility: visible;
        opacity: 1;
        transition: 0.5s;
      }
    }
  }
  &__link-underline {
    color: color("brown");
    text-decoration: underline;

    &:hover {
      color: color("brown");
      text-decoration: underline;
    }
    &:active {
      color: color("brown");
      text-decoration: underline;
    }
    &:focus {
      color: color("brown");
      text-decoration: underline;
    }
  }
  &__cart-wrap {
    .number {
      position: absolute;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: color("brown");
      right: -6px;
      top: -4px;
      border-radius: 50%;
    }
  }
  &__footer-down {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and(max-width: $br-md) {
      justify-content: flex-start;
      flex-wrap: wrap;
      p {
        width: 100%;
      }
      div {
        width: 100%;
      }
    }

    .sosmed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 -8px;
      @media screen and(max-width: $br-md) {
        position: initial;
        transform: initial;
        justify-content: flex-start;
      }
      a {
        display: inline-block;
        margin: 0 8px;
      }
    }
  }
  &__contact-detail {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    border: 1px solid #3a3a3c;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and(max-width: $br-xx) {
      width: 350px;
      height: 350px;
    }
    @media screen and(max-width: $br-xxs) {
      width: 325px;
      height: 325px;
    }

    div {
      div {
        h3 {
          text-decoration: underline;
        }
        .sosmed {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 -8px;

          a {
            display: inline-block;
            margin: 0 8px;
          }
        }
      }
    }
  }
  &__checkbox {
    &.filter {
    }
    input {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: -2;

      &:checked {
        ~ .circle {
          border: 1px solid transparent;
          background-color: color("green");
          .check {
            opacity: 1;
          }
        }
      }
    }

    .circle {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: transparent;
      position: relative;
      border: 1px solid #e5e5e7;

      &.box {
        border-radius: 4px;
      }

      .check {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.5s;
        opacity: 0;
      }
    }
  }
  &__button-file {
    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }

    .action {
      display: flex;
      align-items: center;
    }
  }
  &__field-input {
    width: 100%;
    background-color: transparent;
    outline-style: none;
    border: none;
    border-bottom: 1px solid #3a3a3c;
    border-radius: 0;
    padding: 0;
    height: 54px;
    box-shadow: none;
    color: #c7c7cc;

    &::placeholder {
      color: #c7c7cc;
    }

    &:hover {
      width: 100%;
      background-color: transparent;
      outline-style: none;
      border: none;
      border-bottom: 1px solid #3a3a3c;
      border-radius: 0;
      padding: 0;
      height: 54px;
      box-shadow: none;
    }
    &:active {
      width: 100%;
      background-color: transparent;
      outline-style: none;
      border: none;
      border-bottom: 1px solid #3a3a3c;
      border-radius: 0;
      padding: 0;
      height: 54px;
      box-shadow: none;
    }
    &:focus {
      width: 100%;
      background-color: transparent;
      outline-style: none;
      border: none;
      border-bottom: 1px solid #3a3a3c;
      border-radius: 0;
      padding: 0;
      height: 54px;
      box-shadow: none;
    }
  }
  &__card-blog {
    width: 100%;

    &.absolute {
      height: 490px;

      @media screen and(max-width: 1024px) {
        height: 350px;
      }
      &.slide {
        height: 230px;

        @media screen and(max-width: 1024px) {
          height: 350px;
        }

        .desc {
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 100%;
          padding: 1.3rem;
          background: rgba(28, 28, 30, 0.56);
          backdrop-filter: blur(10px);

          @media screen and(max-width: 1024px) {
            position: absolute;
            left: 0;
            top: initial;
            width: 100%;
            height: auto;
            bottom: 0;
          }

          @media screen and(max-width: $br-xx) {
            padding: 1rem;
          }

          .head {
            display: flex;
            align-items: center;

            .tag {
              padding: 2px 15px;
              border-radius: 30px;
              border: 1px solid transparentize(white, 0.5);
              color: transparentize(white, 0.5);
            }
          }
        }
      }
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .desc {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 2rem 1.5rem;
      background: rgba(28, 28, 30, 0.56);
      backdrop-filter: blur(10px);
      @media screen and(max-width: $br-xx) {
        padding: 1rem;
      }

      .head {
        display: flex;
        align-items: center;

        .tag {
          padding: 2px 15px;
          border-radius: 30px;
          border: 1px solid transparentize(white, 0.5);
          color: transparentize(white, 0.5);
        }
      }
    }
  }
  &__slider-tetimonial {
    margin: 0 -15px;
    .items {
      padding: 0 15px;
    }

    .slick-dots {
      bottom: -3rem;
      li {
        button {
          background: rgba(255, 255, 255, 0.16);
          border-radius: 50%;
          width: 16px;
          height: 16px;
          &::before {
            content: "";
          }
        }

        &.slick-active {
          button {
            background-color: white;
          }
        }
      }
    }
  }
  &__card-testimonial {
    padding: 1.5rem;
    background-color: #f2f2f2;
    display: flex;
    width: 700px;
    @media screen and(max-width: $br-md) {
      width: 100%;
    }

    .profile {
      width: 72px;
      height: 72px;
      object-fit: cover;
      border-radius: 50%;
      margin-top: 2vh;

      @media screen and(max-width: $br-sm) {
        width: 48px;
        height: 48px;
      }
    }

    div {
      .star {
        margin: 0 -5px;
        @media screen and(max-width: $br-xx) {
          width: 16px;
        }
        img {
          margin: 0 5px;
        }
      }
    }
  }
  &__logo-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -15px;

    img {
      margin: 0 15px;
    }

    @media screen and(max-width: 1024px) {
      img {
        width: 200px;
      }
    }

    @media screen and(max-width: $br-md) {
      margin: 0 -8px;
      img {
        margin: 0 -8px;
        width: 135px;
      }
    }
    @media screen and(max-width: $br-xs) {
      margin: 0 -5px;
      img {
        margin: 0 -5px;
        width: 110px;
      }
    }
    @media screen and(max-width: $br-xxs) {
      margin: 0 -5px;
      img {
        margin: 0 -5px;
        width: 100px;
      }
    }
  }
  &__price-list {
    padding: 1.5rem;
    transition: 0.5s;

    &.active {
      background-color: color("green");

      .list {
        .itm {
          svg {
            g {
              path {
                fill: white;
              }
            }
          }
        }
      }

      .btn__action {
        border: 1px solid color("white");
        color: color("white");
      }
    }

    .list {
      margin-bottom: 15px;
      .itm {
        width: 32px;
        text-align: center;
      }
    }

    hr {
      border-top: 1px solid #3a3a3c;
    }

    .btn__action {
      border-radius: 26px;
      border: 1px solid color("black");
      padding: 12px 29px;
      display: inline-block;
      color: color("black");
      transition: 0.5s;
      text-align: center;

      &:hover {
        background-color: white;
        color: color("black") !important;
      }
    }

    &:hover {
      background-color: color("green");

      .list {
        .itm {
          svg {
            g {
              path {
                fill: white;
              }
            }
          }
        }
      }

      .btn__action {
        border: 1px solid color("white");
        color: color("white");
      }
    }
  }
  &__service-wrap {
    padding: 1.5rem;
    transition: 0.5s;
    p {
      opacity: 0.7;
      transition: 0.5s;
    }
    .btn__action {
      border-radius: 26px;
      border: 1px solid color("black");
      padding: 12px 29px;
      display: inline-block;
      color: color("black");
      transition: 0.5s;

      &:hover {
        background-color: white;
        color: color("black") !important;
      }
    }

    &:hover {
      background-color: color("green");

      p {
        opacity: 1;
      }

      .btn__action {
        border: 1px solid color("white");
        color: color("white");
      }
    }
  }
  &__wrap-circle {
    width: 240px;
    height: 240px;
    background: #f2f2f2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and(max-width: 1024px) {
      width: 180px;
      height: 180px;
    }
  }
  &__video-play {
    width: 100%;
    height: 600px;
    position: relative;

    .play {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      background: #1c1c1e29;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and(max-width: 1024px) {
      height: 400px;

      .play {
        width: 80px;
        height: 80px;
      }
    }
    @media screen and(max-width: $br-sm) {
      height: 250px;
    }
  }
}

.list {
  &__get {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }

      div {
        img {
          width: 24px;
          object-fit: contain;
        }
      }
    }
  }
  &__menu-footer {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__normal {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.clamp {
  &__md-3 {
    @media screen and(max-width: $br-md) {
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.text {
}

.images {
  &__blog-detail-head {
    width: 100%;
    object-fit: cover;
    height: 650px;
  }
  &__blog-head {
    width: 100%;
    object-fit: cover;
    height: 435px;

    @media screen and(max-width: 1024px) {
      height: 370px;
    }
    @media screen and(max-width: $br-sm) {
      height: 300px;
    }
    @media screen and(max-width: $br-xs) {
      height: 250px;
    }
  }
  &__line-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 110%;
    max-width: 113%;
  }
  &__right-head {
    position: absolute;
    width: 3.5vw;
    right: -3.5rem;
    top: 20%;
    transform: translateY(-50%);

    @media screen and(max-width: $br-md) {
      right: -2rem;
    }
    @media screen and(max-width: $br-sm) {
      right: -1.5rem;
    }
  }
  &__line-head {
    position: absolute;
    width: 100%;
    bottom: -0.7rem;
    left: 0;
  }
}

.btn {
  &__sub {
    @media screen and(max-width: $br-xx) {
      padding: 12px 25px;
    }
  }
  &__sm-100 {
    @media screen and(max-width: $br-sm) {
      width: 100%;
    }
  }
}

.path {
}

.list {
}

.line {
}

.pt {
  &__100 {
    padding-top: 100px;
    @media screen and(max-width: $br-md) {
      padding-top: 61px;
    }
  }
}

.p {
  &__4 {
    padding: 4rem;
  }
}

.px {
  &-xx-10 {
    @media screen and(max-width: $br-xx) {
      padding: 0 10px;
    }
  }
}

.mt {
  &__min {
    &-17 {
      margin-top: -17rem;

      @media screen and(max-width: $br-xs) {
        margin-top: -14rem;
      }
      @media screen and(max-width: $br-xxs) {
        margin-top: -13rem;
      }
    }
  }
  &__10 {
    margin-top: 10rem;
  }
  &__100 {
    margin-top: 100px;
  }
  &__5 {
    margin-top: 5rem;

    &.md-0 {
      @media screen and(max-width: $br-md) {
        margin-top: 0rem;
      }
    }
  }
  &__10 {
    margin-top: 10rem;

    &.lg-0 {
      @media screen and(max-width: $br-lg) {
        margin-top: 0rem;
      }
    }
  }
  &__18 {
    margin-top: 18rem;

    &.md-0 {
      @media screen and(max-width: $br-md) {
        margin-top: 0rem;
      }
    }
  }
}

.hr {
  &__payment {
    border-top: 1px solid rgba(28, 28, 30, 0.12);
  }
  &__footer {
    border-top: 1px solid #322f37;
  }
  &__line-contact {
    border-top: 1px solid #d9d9d9;
  }
}

.mb {
  &__min {
    &-14 {
      margin-bottom: -14rem;
    }
  }
  &__4 {
    margin-bottom: 4rem;
  }
  &__5 {
    margin-bottom: 5rem;
  }

  &__md-2 {
    @media screen and(max-width: $br-md) {
      margin-bottom: 1.5rem;
    }
  }
}

// typography

.text {
  &__163 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 100px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 90px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 65px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 50px !important;
      }
    }
  }

  &__100 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 80px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 70px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }
  &__150 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 120px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 100px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }

  &__200 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 150px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 125px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-mm) {
        font-size: 100px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 85px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 75px !important;
      }
    }
  }

  &__77 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }
  }

  &__80 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 55px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 65px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }
  }

  &__70 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 60px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 35px !important;
      }
    }
  }

  &__65 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 45px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 28px !important;
      }
    }
  }

  &__60 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 30px !important;
      }
    }
  }

  &__50 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 25px !important;
      }
    }
  }

  &__25 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__45 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 24px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 16px !important;
      }
    }
  }

  &__40 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 28px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 24px !important;
      }
    }
  }

  &__30 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 20px !important;
      }
    }
  }

  &__32 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 24px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 16px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }
  }

  &__28 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__35 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
  }

  &__38 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }
  }

  &__90 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 70px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 55px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xs) {
        font-size: 30px !important;
      }
    }
  }

  &__33 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 18px !important;
      }
    }
  }

  &__18 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 12px !important;
      }
    }
  }

  &__16 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 14px !important;
      }

      &-12 {
        @media screen and(max-width: 1024px) {
          font-size: 12px !important;
        }
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 10px !important;
      }

      &-12 {
        @media screen and(max-width: $br-mm) {
          font-size: 12px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }
    }
  }

  &__20 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 14px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }
  }

  &__120 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 90px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 69px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 75px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 55px !important;
      }
    }
  }

  &__26 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__22 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__12 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 10px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-xs) {
          font-size: 10px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }
  }

  &__14 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 12px !important;
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 9px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-xx) {
          font-size: 8px !important;
        }
      }
    }
  }

  &__27 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }
  }

  &__24 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }
  }

  &__25 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__58 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 45px !important;
      }
    }
  }

  &__48 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 35px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 30px !important;
      }
    }
    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 24px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 18px !important;
      }
    }
  }
}

.w {
  &__mm-100 {
    @media screen and(max-width: $br-mm) {
      width: 100%;
    }
  }
}

.wrap {
  &__flex {
    &-1024 {
      &-100 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
        }
      }

      &-50 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-90 {
        &-3 {
          @media screen and(max-width: 1024px) {
            flex-flow: wrap;
          }
          > .grid {
            @media screen and(max-width: 1024px) {
              flex: 0 0 calc(90% / 3);
              max-width: calc(90% / 3);
            }
          }
        }
      }

      &-auto {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-md {
      &-100 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-auto {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-sm {
      &-100 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-xs {
      &-100 {
        @media screen and(max-width: $br-xs) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-xs) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-mm {
      &-100 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-4 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 calc(100% / 6);
            max-width: calc(100% / 6);
          }
        }
      }
    }
  }
}

// animated
@keyframes move1 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, 14.5rem);
  }
  75% {
    transform: translate(27rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move12 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(9.8rem, 13rem);
  }
  75% {
    transform: translate(19.7rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move2 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-27rem, 0rem);
  }
  75% {
    transform: translate(-13.4rem, 14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move22 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-19.5rem, 0rem);
  }
  75% {
    transform: translate(-10rem, 13rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move3 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, -14.5rem);
  }
  75% {
    transform: translate(-13.5rem, -14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move32 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(10rem, -13rem);
  }
  75% {
    transform: translate(-10rem, -13rem);
  }
  100% {
    transform: translate(0);
  }
}
